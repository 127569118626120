import React from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import store from './shared/store/index-store'

import App from './app/App'

const element = document.querySelector('#tokyo__react-login-popup')

const elementMobile = document.querySelector('#tokyo__react-login-popup-mobile')

const root = ReactDOM.createRoot(element)

const rootMobile = ReactDOM.createRoot(elementMobile)

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <App/>
        </Provider>
    </BrowserRouter>
)

rootMobile.render(
    <BrowserRouter>
        <Provider store={store}>
            <App/>
        </Provider>
    </BrowserRouter>
)