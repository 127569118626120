import React, {useState} from 'react';
import PinInput from '../../../../shared/UI/PinInput/PinInput';
import Button from "../../../../shared/UI/Button/Button";
import s from './style.module.css'
import {selectViewerData, sendSmsToViewer} from "../../../../entities/Viewer/Model/viewer";
import ReSendSmsButton from "../../../../shared/UI/reSendSmsButton/ReSendSmsButton";
import {useDispatch, useSelector} from "react-redux";
import {viewerAPI} from "../../../../shared/api/api";

const RegistrationSmsCodeChecker = ({closePopup}) => {

  const initDigits = ['', '', '', ''];
  const [digits, setDigits] = useState(initDigits);
  const dispatch = useDispatch()
  const viewerData = useSelector(selectViewerData)

  const formData = {
    ...viewerData,
    confirmCode: digits,
  }

  const HandleSubmit = async (e) => {
    e.preventDefault()
    if (Array.isArray(formData.confirmCode)) formData.confirmCode = formData.confirmCode.join("");
    try {
      const response = await viewerAPI.confirmRegistration(formData)
      switch (response.data.isSuccess) {
        case true:
          location.reload()
          closePopup()
          break;
        case false:
          alert('Неверный пинкод')
          break;
      }
    } catch (e) {
      alert(e.response.data.message)
      console.log(e)
    }
  }

  function getCookie(name) 
  {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  return (
    <div className={s.codeChecker__container}>
      <div className={s.codeChecker__text}>
        На номер поступит звонок с неизвестного номера
        Введите 4 последние цифры номера в поле ниже
      </div>
      <form className={s.codeChecker__formContainer} onSubmit={HandleSubmit}>
        <div className={s.codeChecker__pinText}>
          Код*
        </div>
        <div className={s.codeChecker__pinWrap}>
          <PinInput digits={digits} changeHandler={setDigits} onSubmit={HandleSubmit}/>
        </div>
        <ReSendSmsButton
          seconds={30}
          reSendDisabledBtnText={'Отправить SMS с кодом через'}
          reSendActiveBtnText={'Отправить SMS'}
          reSendFunc={() => {
            var recaptchaResp = getCookie('recaptcha_resp');
            if (recaptchaResp == "undefined")
            {
              alert('Истекло время проверки на робота. Пожалуйста, пройдите проверку и попробуйте снова.');
              location.reload();
            }
            else
            {
              dispatch(sendSmsToViewer({phone: formData.phone, recaptcha_resp: recaptchaResp}))
            }
          }}
        />
        <Button
          text={'Создать профиль'}
          isDisabled={false}
          type={"submit"}
          styles={{margin: '40px auto 0'}}
        />
      </form>
    </div>
  );
};

export default RegistrationSmsCodeChecker;