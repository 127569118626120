import React from 'react';
import s from './styles.module.css'

const Button = ({text, isDisabled, onClickEvent, type, styles, svgSrc}) => {

    const clicked = (e) => {
        e.stopPropagation()
        if (onClickEvent){
            onClickEvent()
        }
    }

    return (
        <button className={s.validBtn} disabled={isDisabled} onClick={clicked} type={type} style={styles}>
            {
                svgSrc &&
                    <img src={svgSrc} alt={'svg'} className={s.svgInBtn}/>
            }
            {text}
        </button>
    );
};

export default Button;