import React, {useEffect, useState} from 'react';
import s from './style.module.css'

import {IMaskInput} from "react-imask";
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from "react-redux";

import Input from "../../../../shared/UI/Input/Input";
import Button from "../../../../shared/UI/Button/Button";
import Checkbox from "../../../../shared/UI/Checkbox/Checkbox";
import {UserGender} from "../../../../../../profile/src/components/Profile/UserGender/UserGender"

import {registerNewUser} from "../../../../entities/Viewer/Model/viewer";
import {viewerAPI} from "../../../../shared/api/api";

const Registration = ({changeModalType}) => {

  const dispatch = useDispatch()
  const [isDisabled, setIsDisabled] = useState(true)

  const {
    handleSubmit,
    register,
    getValues,
    control,
    reset,
    formState: {errors, isValid}
  } = useForm(
    {
      mode: 'onBlur',
      reValidateMode: 'onChange',
      defaultValues: {
        firstName: '',
        lastName: '',
        birthDay: '',
        phone: '',
        recaptcha_resp: '',
        gender: 'Unknown',
        opd_agreement: true,
        newsletters_agreement: true
      }
    })

  useEffect(() => {
    if (isValid) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [isValid])
  const registration = async (data) => {
    try
    {
      let responseElem;
      if (responseElem = document.querySelector('#recaptchaEnter .g-recaptcha-response'))
      {
        let response = responseElem.value;
        data.recaptcha_resp = response;
      }
      if (data.recaptcha_resp === '')
      {
        throw new Error("Извините! Вы не прошли проверку на робота. \n Попробуйте ещё раз, пройдя проверку.");
      }
      formatDatePut(data);
      const isUserExist = await viewerAPI.isUserExist(data.phone)
      switch (isUserExist.data.message) {
        case 'success':
          throw new Error('Пользователь с таким номером уже существует')
          break;
        case 'not found':
            await setCookie('recaptcha_resp', data.recaptcha_resp);
            await dispatch(registerNewUser({formData: data}))
          changeModalType('Регистрация проверка смс', 'Регистрация')
          break;
      }
    }
    catch(e)
    {
      alert(e);
    }
  }

  function setCookie(name, value) 
  {
      let updatedCookie = name + "=" + value + ";max-age=120;path=/;";
      
      document.cookie = updatedCookie;
  }

  const formatDatePut = (formData) => {
    if (!formData?.birthDay) return
    if (!formData.birthDay.includes("-")) return

    let formatedDate = formData.birthDay.split("-")
    formData.birthDay = formatedDate[2] + "." + formatedDate[1] + "." + formatedDate[0]
  }

  return (
    <>
      <form onSubmit={handleSubmit(registration)}>
        <div className={s.registration__personalDataGridBlock}>
          <div>
            <Controller
              name="firstName"
              control={control}
              rules={{
                pattern: {
                  value: /^\s*([а-яА-Яёa-zA-Z]+\s*){1,3}$/,
                  message: 'Введите корректное имя'
                },
                required: {
                  value: true,
                  message: 'Это поле необходимо заполнить.'
                }
              }}
              render={({field}) => (
                <Input
                  fieldName={'Имя*'}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder={'Укажите имя'}
                  error={errors?.firstName}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="lastName"
              rules={{
                pattern: {
                  value: /^\s*([а-яА-Яёa-zA-Z]+\s*){1,3}$/,
                  message: 'Введите корректную фамилию'
                },
                required: {
                  value: true,
                  message: 'Это поле необходимо заполнить.'
                }
              }}
              control={control}
              render={({field}) => (
                <Input
                  fieldName={'Фамилия*'}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder={'Укажите фамилию'}
                  error={errors?.lastName}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="birthDay"
              control={control}
              rules={{
                pattern: {
                  value: /^(19|20)\d\d[-/.](0[1-9]|1[0-2])[-/.](0[1-9]|[12][0-9]|3[01])$/,
                  message: 'Введите корректную дату рождения',
                },
                required: {
                  value: true,
                  message: 'Это поле необходимо заполнить.'
                },
                valueAsDate: true,
              }}
              render={({field}) => (
                <Input
                  fieldName={'Дата рождения*'}
                  value={field.value}
                  onChange={(e) => {
                    if (e.target.value.split("-")[0].match(/\b\d{4}\b/))
                    field.onChange(e.target.value);
                    field.onBlur();
                  }}
                  placeholder={'ДД.ММ.ГГГГ'}
                  error={errors?.birthDay}
                  type={'date'}
                />
              )}
            />
          </div>
          <div>
            <p className={s.labelP}>Телефон*</p>
            <div className={s.inputWrap}>
              <Controller
                name="phone"
                control={control}
                rules={{
                  pattern: {
                    value: /(^8|7|\+7)((\d{10})|(\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}))/,
                    message: 'Введите корректный номер телефона'
                  },
                  required: {
                    value: true,
                    message: 'Это поле необходимо заполнить.'
                  }
                }}
                render={({field}) => (
                  <IMaskInput
                    mask={'+{7} (#00) 000-00-00'}
                    definitions={{'#': /9/}}
                    value={field.value}
                    onAccept={value => field.onChange(value.replace(/\D/g, ''))}
                    placeholder={'+7'}
                  />
                )}
              />
              {errors?.phone?.message && <p className={s.error}>{errors?.phone?.message}</p>}
            </div>
          </div>
        </div>
        <Controller
          name="gender"
          control={control}
          render={({field}) => (
            <UserGender
              fieldName={'Пол'}
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
        <div className={s.checkbox__container}>
          <Controller
            name="opd_agreement"
            control={control}
            render={({field}) => (
              <Checkbox
                onChange={(e) => field.onChange(e.target.checked)}
                checked={field.value}
                label={'Нажимая кнопку «Оформить заказ», я даю своё согласие на'}
              />
            )}/>
        </div>
        <div className={s.opdAgreement}>
          <a href="#" target={"_blank"}>обработку моих персональных данных</a>
        </div>
        <div className={s.checkbox__container}>
          <Controller
            name="newsletters_agreement"
            control={control}
            render={({field}) => (
              <Checkbox
                onChange={(e) => field.onChange(e.target.checked)}
                checked={field.value}
                label={'Согласие на получение e-mail и SMS-рассылок'}
              />
            )}
          />
        </div>
        <div 
            id="recaptchaEnter" 
            data-sitekey="6LdAlKMpAAAAAH1UBhB4grVmMu_dBBJZB6Qun4uT" 
            className='g-recaptcha'
        ></div>
        <div className={s.submitButton_container}>
          <Button
            text={'Создать профиль'}
            type={'submit'}
            isDisabled={isDisabled}
          />
        </div>
      </form>
    </>
  );
};

export default Registration;