import React from 'react';
import s from './style.module.css'
import registrationSVG from "../../../../shared/assets/svg/registrationButtonSVG.svg";
import Button from "../../../../shared/UI/Button/Button";

const NotRegisteredYet = ({changeModalType}) => {
    return (
        <>
            <div className={s.undefinedNumberText}>Кажется, этот телефон ещё <span className={s.undefinedNumberText__red}>не&nbsp;зарегистрирован</span>. Пожалуйста, создайте свой профиль, нажав кнопку ниже.</div>
            <div className={s.registerBtn_wrap}>
                <Button
                    text={'Регистрация'}
                    isDisabled={false}
                    onClickEvent={()=>{changeModalType('Регистрация', 'Регистрация')}}
                    type={'button'}
                    svgSrc={registrationSVG}
                    styles={{color:'#2E2E2E', background: '#F6F6F6'}}
                />
            </div>
        </>
    );
};

export default NotRegisteredYet;