import {
    createAsyncThunk,
    createSlice,
} from "@reduxjs/toolkit";

import {viewerAPI} from "../../../shared/api/api";

const initialState = {
    isViewerAuthorized: false,
    viewerPhone: null,
    status: 'idle',
    error: null,
    viewerData: null
}

export const sendSmsToViewer = createAsyncThunk('viewer/getSmsCode', async ({phone, recaptcha_resp}) => {
    const response = await viewerAPI.sendSMSCode(phone, recaptcha_resp)
    return { phone, recaptcha_resp }
})

export const sendCallToViewer = createAsyncThunk('viewer/getCallCode', async ({phone, recaptcha_resp}) => {
    const response = await viewerAPI.sendCallCode(phone, recaptcha_resp)
    return { phone, recaptcha_resp }
})

export const sendPasswordRecoveryCodeToViewer = createAsyncThunk('viewer/getPasswordRecoveryCode', async ({phone}) => {
    const response = await viewerAPI.sendPasswordRecoveryCode(phone)
    return { phone }
})

export const loginViewerWithPin = createAsyncThunk('viewer/loginWithPin', async ({formData}) => {
    const response = await viewerAPI.loginViewerWithPin(formData)
    return { status: response.status }
})

export const registerNewUser = createAsyncThunk('viewer/registerNewUser', async ({formData}) => {
    const response = await viewerAPI.registerNewUser(formData)
    return { data: formData, }
})


export const viewerModel = createSlice({
    name: 'viewer',
    initialState,
    reducers: {},
    extraReducers(builder){
        builder
            .addCase(sendSmsToViewer.fulfilled, (state, action) => {
                state.viewerPhone = action.payload.phone
            })
            .addCase(sendCallToViewer.fulfilled, (state, action) => {
                state.viewerPhone = action.payload.phone
            })
            .addCase(sendPasswordRecoveryCodeToViewer.fulfilled, (state, action) => {
                state.viewerPhone = action.payload.phone
            })
            .addCase(registerNewUser.fulfilled, (state, action) => {
                state.viewerData = action.payload.data
            })

    }
})

export const { increment } = viewerModel.actions


export default viewerModel.reducer

export const selectViewerPhone = state => state.viewer.viewerPhone

export const selectViewerData = state => state.viewer.viewerData