import React from 'react'
import s from './styles.module.scss'

export function UserGender({value, onChange , disabled, fieldName}) {
  return (
    <>
      <p className={s.labelP}>{fieldName}</p>
      <div className={s.genderToggle__container}>
        <div className={s.genderToggle}>
          <div className={s.genderToggle__item}>
            <input id="gen-1"
                   type="radio"
                   name="gender"
                   value="Female"
                   checked={value === 'Female'}
                   onChange={onChange}
                   disabled={disabled}
            />
            <label htmlFor="gen-1">Ж</label>
          </div>
          <div className={s.genderToggle__item}>
            <input id="gen-2"
                   type="radio"
                   name="gender"
                   value="Male"
                   checked={value === 'Male'}
                   onChange={onChange}
                   disabled={disabled}
            />
            <label htmlFor="gen-2">М</label>
          </div>
        </div>
        <div className={s.genderToggle__noGender}>
          <input id="gen-3"
                 type="radio"
                 name="gender"
                 value="Unknown"
                 checked={value !== 'Female' && value !== 'Male'}
                 onChange={onChange}
                 disabled={disabled}
          />
          <label htmlFor="gen-3">Не указывать</label>
        </div>
      </div>
    </>
  )
}