import React, {useState} from 'react';
import './styles/index.css'
import s from './styles/style.module.css'
import AuthForm from "../widgets/auth-form/AuthForm";

const App = () => {

    const [popupOpened, setPopupOpened] = useState(false)

    return (
        <div className="login-popup__app-container header-mob-text-img-auth-enter mobil-font-header-list">
            {
                popupOpened &&
                    <AuthForm
                        closeAuthForm={()=>{setPopupOpened(false)}}
                    />
            }
            <button className={s.loginBtn} onClick={()=>{setPopupOpened(true)}}>
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.10743 0.700694C8.10743 0.313711 8.42113 0 8.80812 0H11.2922C13.34 0 15 1.66002 15 3.70776V11.5523C15 13.6 13.34 15.2601 11.2922 15.2601H8.80812C8.42113 15.2601 8.10743 14.9464 8.10743 14.5594C8.10743 14.1724 8.42113 13.8587 8.80812 13.8587H11.2922C12.566 13.8587 13.5986 12.8261 13.5986 11.5523V3.70776C13.5986 2.43398 12.566 1.40139 11.2922 1.40139H8.80812C8.42113 1.40139 8.10743 1.08768 8.10743 0.700694ZM0 7.62993C0 7.24294 0.313711 6.92923 0.700694 6.92923H8.53534L6.53293 5.29762C6.23293 5.05317 6.18789 4.6118 6.43234 4.3118C6.67678 4.0118 7.11815 3.96676 7.41815 4.21121L10.9482 7.08753C11.1104 7.21975 11.2051 7.41757 11.2063 7.62687C11.2074 7.83617 11.1149 8.03503 10.9542 8.16904L7.42412 11.1107C7.12683 11.3585 6.68499 11.3183 6.43725 11.021C6.18951 10.7238 6.22967 10.2819 6.52697 10.0342L8.57118 8.3306H0.700694C0.313711 8.3306 0 8.0169 0 7.62993Z" fill="#2E2E2E"/>
            </svg>
                &nbsp;Вход
            </button>
        </div>
    );
};

export default App;