import React, {useEffect, useState} from 'react';
import s from './style.module.css'

const ReSendSmsButton = ({seconds, reSendDisabledBtnText, reSendActiveBtnText, reSendFunc}) => {

    const [paused, setPaused] = useState(false);
    const [over, setOver] = useState(false);
    const [time, setTime] = useState(seconds);

    const tick = () => {
        if (paused || over) return;

        if (time === 0) {
            setOver(true);
        }  else {
            setTime(time - 1);
        }
    };

    const reset = () => {
        setTime(parseInt(seconds));
        setPaused(false);
        setOver(false);
        if (reSendFunc){
            reSendFunc()
        }
    };

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    });

    return (
        <div className={s.btnWrap}>
            {
                over ?
                    <button onClick={() => reset()} className={s.btnActive}>
                        {reSendActiveBtnText}
                    </button>
                    :
                    <div className={s.btnDisabled}>
                        <p>{reSendDisabledBtnText} {time.toString()} сек.</p>
                    </div>
            }
        </div>
    );

};

export default ReSendSmsButton;