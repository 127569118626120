import React, {useState} from 'react';
import s from './styles.module.css'
import CloseButton from "../../shared/UI/CloseButton/CloseButton";
import LoginWithSMS from "../../features/auth-form/login/LoginWithSMS/LoginWithSMS";
import LoginWithSMSCodeChecker from "../../features/auth-form/login/LoginWithSMSCodeChecker/LoginWithSMSCodeChecker";
import clsx from "clsx";
import NotRegisteredYet from "../../features/auth-form/registration/ NotRegisteredYet/NotRegisteredYet";
import Registration from "../../features/auth-form/registration/Registration/Registration";
import RegistrationSMSCodeChecker
    from "../../features/auth-form/registration/RegistrationSMSCodeChecker/RegistrationSMSCodeChecker";

const AuthForm = ({closeAuthForm}) => {
    const [currentAuthModal, setCurrentAuthModal] = useState('Вход в профиль')
    const [currentModalTitle, setCurrentModalTitle] = useState('Вход в профиль')

    const popupContainer = clsx(s.authForm__popupContainer, {
        [s.authForm__popupContainerTight]: currentAuthModal === 'Вход по смс'
                                        || currentAuthModal === 'Номер ещё не зарегистрирован'
                                        || currentAuthModal === 'Создать новый пароль',
        [s.authForm__popupContainerRgb]: currentAuthModal === 'Номер ещё не зарегистрирован'
    })

    const closeAuthPopup = () => {
        closeAuthForm()
        setCurrentAuthModal('Вход в профиль')
        setCurrentModalTitle('Вход в профиль')
    }

    const changeAuthModelHandler = (newModal, modalTitle) => {
        setCurrentAuthModal(newModal)
        setCurrentModalTitle(modalTitle)
    }

    const currentModal = {
        'Вход в профиль'               : LoginWithSMS,
        'Вход по смс'                  : LoginWithSMSCodeChecker,
        'Номер ещё не зарегистрирован' : NotRegisteredYet,
        'Регистрация'                  : Registration,
        'Регистрация проверка смс'     : RegistrationSMSCodeChecker
    }

    const CurrentModalComponent = currentModal[currentAuthModal]

    return (
        <div className={s.authForm__wrap}>
            <div className={popupContainer}>

                <div className={s.closePopup__wrap}>
                    <CloseButton
                        onCloseButtonClicked={closeAuthPopup}
                    />
                </div>

                <div className={s.authForm__title}>
                    <p>{currentModalTitle}</p>
                </div>

                <CurrentModalComponent
                    changeModalType={changeAuthModelHandler}
                    closePopup={closeAuthPopup}
                />

            </div>
        </div>
    );
};

export default AuthForm;