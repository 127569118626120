import React from 'react';
import s from './styles.module.css'

const CloseButton = ({onCloseButtonClicked}) => {

    const closeButtonClicked = (e) => {
        e.stopPropagation()
        onCloseButtonClicked()
    }

    return (
        <div className={s.closeButton}>
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={closeButtonClicked}>
                <circle cx="17.5" cy="17.5" r="17" transform="matrix(-1 0 0 1 35 0)" stroke="#2E2E2E"/>
                <path d="M10.4502 10.7271L24.2723 24.5491" stroke="#2E2E2E" strokeLinecap="round"/>
                <path d="M24.2725 10.7271L10.4504 24.5491" stroke="#2E2E2E" strokeLinecap="round"/>
            </svg>
        </div>
    );
};

export default CloseButton;