import {instance} from "./http/axios-instance";

export const viewerAPI = {

    async isUserExist(phone){
        return await instance.get(`/users/`+ phone.replace(/\D/g, ""))
    },

    async sendSMSCode(phone, recaptcha_resp = null) {
        return await instance.post('/users/' + phone.replace(/\D/g) + '/' + recaptcha_resp + '/auth/sms')
    },

    async sendCallCode(phone, recaptcha_resp = null) {
        return await instance.post('/users/' + phone.replace(/\D/g) + '/' + recaptcha_resp + '/auth/call')
    },

    async loginViewerWithPin(formData){
        return await instance.post('/users/'+formData.phone.replace(/\\D/g)+'/auth/confirm', formData)
    },

    async registerNewUser(formData){
        return await instance.post(`/users/`+ formData.phone.replace(/\D/g, "") + `/` + formData.recaptcha_resp + `/register`, ) //return await instance.post(`/users/`+ formData.phone.replace(/\D/g, "") + `/` + formData.recaptcha_resp + `/register`, )
    },

    async confirmRegistration(formData){
        return await instance.post(`/users/`+ formData.phone.replace(/\D/g, "")+ `/register-confirm`, formData)
    }
}